import { graphql, useStaticQuery } from "gatsby";

export const GET_ALL_SHOPIFY_PRODUCTS = graphql`
  query {
    allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          shopifyId
          title
          availableForSale
          handle
          productType
          createdAt
          descriptionHtml
          description
          shopifyId
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            id
            shopifyId
            title
            price
            compareAtPrice
            compareAtPriceV2 {
              amount
              currencyCode
            }
            priceV2 {
              amount
              currencyCode
            }
            availableForSale
            shopifyId
            selectedOptions {
              name
              value
            }
            image {
              id
              originalSrc
            }
          }
        }
      }
    }
  }
`;

export const useGetAllShopifyProducts = () =>
  useStaticQuery(GET_ALL_SHOPIFY_PRODUCTS);
