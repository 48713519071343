/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import * as s from "./MobileHeader.module.scss";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import logo1 from "../../../images/logo1.svg";
import { Link, navigate } from "gatsby";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { userConstants } from "../../../modules/auth/constants";
import drop from "../../../images/dropdown.svg";
import { AllCartItems } from "../../organisms/AllCartItems/AllCartItems";

const cn = classNames.bind(s);

interface IMobileHeader {
  visible: boolean;
  setVisible: any;
}

const MobileHeader = ({ setVisible, visible }: IMobileHeader) => {
  const [sidebar, setSidebar] = useState(false);
  // const [visible, setVisible] = useState<boolean>(false);
  const [checkoutId, setCheckoutId] = useState<string | null>(null);

  const handleCart = () => {
    setVisible(true);
  };

  const handleCartVisibility = (visible: boolean) => {
    setVisible(visible);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const [token, setToken] = useState<string | null>();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    }
    setCheckoutId(localStorage.getItem("checkoutId"));
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <div className={s.navbar}>
        <Link to="#" className={s.menuBars}>
          <FaIcons.FaBars
            onClick={() => setSidebar(!sidebar)}
            style={{ width: "1.5em", height: "1.5em" }}
          />
        </Link>
        <img
          src={logo1}
          className={s.image}
          alt="logo"
          onClick={() => navigate("/")}
        />
        <div onClick={() => handleCart()} style={{ marginRight: "1rem" }}>
          {" "}
          <BsFillCartCheckFill
            fill="#173a35"
            style={{ width: "1.5em", height: "1.5em" }}
          />
        </div>
      </div>

      <nav className={sidebar ? cn(s.navMenu, s.active) : s.navMenu}>
        <div className={s.navMenuItems} onClick={() => setSidebar(!sidebar)}>
          <Link to="#">
            <AiIcons.AiOutlineClose
              style={{ width: "1.5em", height: "1.5em" }}
            />
          </Link>

          <img src={logo1} className={s.logo} alt="logo" />

          <Link to="/">Home</Link>
          <Link to="/products">Products</Link>
          <Link to="/meet-your-mushrooms"> Meet Your Mushrooms</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/blog"> Blog</Link>
          <Link to="/contact">Contact</Link>
          <p className={s.profile} onClick={() => handleCart()}>
            Cart
          </p>

          <ul className={s.mainDropdown}>
            <li
              className={s.liWrapper}
              onClick={() => {
                setShowDropdown(!showDropdown);
                setSidebar(true);
              }}
            >
              <p className={s.profile}>Profile</p>
              <img src={drop} alt="dropdown"></img>
            </li>

            <ul className={s.dropdown}>
              <li>
                <Link to="/profile" className={cn(s.profile, s.logText)}>
                  Account
                </Link>
              </li>
              <li>
                {token ? (
                  <>
                    <Link
                      to="/login"
                      className={cn(s.profile, s.logText)}
                      onClick={() => {
                        dispatch({ type: userConstants.LOGOUT });
                        localStorage.removeItem("token");
                      }}
                    >
                      Log Out
                    </Link>
                  </>
                ) : (
                  <Link to="/login" className={cn(s.profile, s.logText)}>
                    Log In
                  </Link>
                )}
              </li>
            </ul>
          </ul>
        </div>
      </nav>

      <AllCartItems
        cart={true}
        checkoutId={checkoutId}
        visibility={visible}
        handleCart={handleCartVisibility}
      />
    </>
  );
};
export default MobileHeader;
