// extracted by mini-css-extract-plugin
export var addButton = "CartItem-module--add-button--bBroR";
export var amount = "CartItem-module--amount--t4xck";
export var amountWrapper = "CartItem-module--amount-wrapper--QI2lw";
export var amountWrapper1 = "CartItem-module--amount-wrapper-1--XoxB5";
export var anotherWrapper = "CartItem-module--another-wrapper--0-HrB";
export var anotherWrapperCart = "CartItem-module--another-wrapper-cart--mBuco";
export var anotherWrapperCompleteSet = "CartItem-module--anotherWrapperCompleteSet--vygI1";
export var binImg = "CartItem-module--bin-img--yWcgG";
export var cancelhelptext = "CartItem-module--cancelhelptext--xWg7O";
export var container = "CartItem-module--container--IZQRH";
export var containerCart = "CartItem-module--container-cart--o8HBg";
export var containerCart1 = "CartItem-module--container-cart-1--Lr4zU";
export var deleteWrapper = "CartItem-module--delete-wrapper--7a+iH";
export var gummies = "CartItem-module--gummies--r9Sfi";
export var imageWrapper = "CartItem-module--image-wrapper--ul2Ze";
export var mainContentWrapper = "CartItem-module--main-content-wrapper--NvJ0y";
export var price = "CartItem-module--price--0ZJbI";
export var priceComplete = "CartItem-module--priceComplete--7+92l";
export var priceContainer = "CartItem-module--priceContainer--MbCr+";
export var priceDiscount = "CartItem-module--priceDiscount--i9W2S";
export var spanText = "CartItem-module--span-text--5Ch32";
export var subsText = "CartItem-module--subsText--wrcob";
export var text = "CartItem-module--text--yDKiS";
export var textCart = "CartItem-module--text-cart--qtzYv";
export var textWrapper = "CartItem-module--text-wrapper--9BgXf";
export var textWrapperCart = "CartItem-module--text-wrapper-cart--nYkyz";
export var textWrapperCompleteSet = "CartItem-module--text-wrapper-complete-set--j5ItY";
export var updateWrapper = "CartItem-module--update-wrapper--rDwBw";