import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { devToolsEnhancer } from "redux-devtools-extension/logOnlyInProduction";
import rootReducer from "./reducers";
import root from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const configureStore = (initialState = {}) => {
  // const appReducer = combineReducers(reducers);
  const appReducer = rootReducer;

  return createStore(
    appReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      devToolsEnhancer({ trace: true, traceLimit: 25 })
    )
  );
};
export const store = configureStore({});

sagaMiddleware.run(root);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
