import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-fetch";

import React from "react";

// const middlewareLink = setContext(() => ({
//     headers: {
//         'X-Shopify-Storefront-Access-Token': accessToken
//     }
// }));

export default ({ children }: { children: JSX.Element }) => {
  const shopName = "troop-demo";
  const accessToken =
    process.env.GATSBY_ACCESS_TOKEN || "c04aaf050f546603ceb3be59145d2424";
  // const apiPath = "api/graphql";
  const apiPath = "api/2021-10/graphql.json";
  // const apiPath = "api/2021-01/graphql.json";
  // const apiPath = `api/2021-04/graphql.json`; //'api/graphql'

  const httpLink = createHttpLink({
    uri: `https://${shopName}.myshopify.com/${apiPath}`,
    fetch,
  });

  const authHttpLink = setContext(() => ({
    headers: {
      "X-Shopify-Storefront-Access-Token": accessToken,
    },
  }));

  const client = new ApolloClient({
    link: authHttpLink.concat(httpLink),
    cache: new InMemoryCache(),
    headers: {
      "X-Shopify-Storefront-Access-Token": accessToken || "",
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
