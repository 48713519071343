/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P, M> extends Action<T> {
  payload: P;
  meta: M;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P, M>(
  type: T,
  payload: P,
  meta?: M
): ActionWithPayload<T, P, M>;

export function createAction<T extends string, P, M>(
  type: T,
  payload?: P,
  meta?: M
): Action<T> | ActionWithPayload<T, P, M> {
  return payload === undefined ? { type } : { type, payload, meta };
}

type FunctionType = (...args: any[]) => any;

interface ActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;
