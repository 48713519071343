import { gql } from "@apollo/client";

export const UPDATE_CART = gql`
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        discountCodes {
          applicable
          code
        }
        checkoutUrl
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
        }
        lines(first: 10) {
          edges {
            node {
              id
              quantity

              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
                subtotalAmount {
                  amount
                  currencyCode
                }
              }

              merchandise {
                ... on ProductVariant {
                  id
                  priceV2 {
                    amount
                    currencyCode
                  }
                  product {
                    title
                    id
                    handle
                    images(first: 2) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                  }
                }
              }
              attributes {
                key
                value
              }
            }
          }
        }
        buyerIdentity {
          email
          phone
          customer {
            id
          }
          countryCode
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
