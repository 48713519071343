import { gql } from "@apollo/client";

// signup
export const CREATE_CUSTOMER_MUTATION = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// login
export const CUSTOMER_ACCESS_TOKEN_CREATE = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const EDIT_PROFILE_MUTATION = gql`
  mutation customerUpdate($input: CustomerUpdateInput!, $token: String!) {
    customerUpdate(customerAccessToken: $token, customer: $input) {
      customer {
        id
        firstName
        lastName
        email
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const CHANGE_USER_ADDRESS = gql`
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
        city
        zip
        province
        address1
        address2
        country
        province
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_USER_ADDRESS = gql`
  mutation customerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
        addresses(first: 5) {
          edges {
            node {
              address1
            }
          }
        }
        defaultAddress {
          address1
          city
          country
          address2
          id
        }
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// update customer - use this for a newsletter subscription

export const UPDATE_CUSTOMER = gql`
  mutation customerUpdate($input: CustomerUpdateInput!, $token: String!) {
    customerUpdate(customer: $input, customerAccessToken: $token) {
      customer {
        id
        firstName
        lastName
        acceptsMarketing
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CUSTOMER_ADDRESS_UPDATE = gql`
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
        address1
        address2
        city
        country
        zip
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

//recovery email
export const RECOVER_EMAIl = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CUSTOMER_PASSWORD_RESET = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
