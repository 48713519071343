// import { graphql, useStaticQuery } from "gatsby";
import { gql } from "@apollo/client";

// export const GET_SELLING_PLANS = graphql`
//   query {
//     products(first: 3) {
//       edges {
//         node {
//           id
//           sellingPlanGroups(first: 2) {
//             edges {
//               node {
//                 name
//                 sellingPlans(first: 2) {
//                   edges {
//                     node {
//                       id
//                       name
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// export const GET_SELLING_PLANS = graphql`shopifyProduct {
//     similar {
//       title
//       handle
//       products {
//         id
//         handle
//       }
//     }
//   }`;

export const PLANS = gql`
  {
    products(first: 10) {
      edges {
        node {
          requiresSellingPlan
          productType
          id
          title
          variants(first: 5) {
            edges {
              node {
                id
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
          sellingPlanGroups(first: 7) {
            edges {
              node {
                name
                sellingPlans(first: 5) {
                  edges {
                    node {
                      name
                      id
                      priceAdjustments {
                        adjustmentValue {
                          ... on SellingPlanPercentagePriceAdjustment {
                            adjustmentPercentage
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PLANS_2 = gql`
  {
    products(first: 10) {
      edges {
        node {
          requiresSellingPlan
          sellingPlanGroups(first: 7) {
            edges {
              node {
                name
                sellingPlans(first: 5) {
                  edges {
                    node {
                      name
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// export const useGetAllSellingPlans = () => useStaticQuery(GET_SELLING_PLANS);

export const GET_PLAN_BY_PRODUCT_ID = gql`
  query getProductPlan($id: ID!) {
    product(id: $id) {
      id
      sellingPlanGroups(first: 2) {
        edges {
          node {
            name
            sellingPlans(first: 2) {
              edges {
                node {
                  id
                  name
                  priceAdjustments {
                    adjustmentValue {
                      ... on SellingPlanPercentagePriceAdjustment {
                        adjustmentPercentage
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
