import React, { useEffect, useState } from "react";
import * as s from "./AddToCartButton.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { selectCartId } from "../../../modules/selectors";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_CART,
  ADD_TO_CART,
  CART_BUYER_IDENTITY,
} from "../../../g/mutations/createCart";
import { cartConstants } from "../../../modules/cart/constants";
import { GET_USER } from "../../../g/queries/get-user-info";
import classNames from "classnames";
import { ANALYTICS } from "../../../libs/analytics";
import { UPDATE_CART } from "../../../g/mutations/updateCart";
import CurrencyList from "currency-list";
import { useWindowSize } from "../../../hooks";

const cn = classNames.bind(s);

interface IProduct {
  variant?: TroopState.Variant;
  checkedPrice?: number;
  quantity?: number;
  setQuantity?: (a: number) => void;
  planId?: string | null;
  addButton?: boolean;
  isBuyNow?: boolean;
  availableForSale?: boolean;
  oneTime?: boolean;
  monthly?: boolean;
  buttonText?: string;
  isFixed?: boolean;
}
const AddToCartButton = ({
  variant,
  quantity,
  setQuantity,
  planId,
  addButton,
  isBuyNow,
  availableForSale,
  oneTime,
  monthly,
  buttonText = "Add To Cart",
  isFixed,
  checkedPrice,
}: IProduct) => {
  const dispatch = useDispatch();
  const width = useWindowSize().width;
  const [cartData, setCartData] = useState<TroopState.EndpointPayload>({});
  const [token, setToken] = useState<string | null>(null);
  const [promoCode, setPromoCode] = useState<string | null>();
  const [customer, setCustomer] = useState<TroopState.User>();
  const [cartId, setCartId] = useState<string | null>(
    useSelector(selectCartId)
  );

  useEffect(() => {
    setCartId(localStorage.getItem("cartId"));
  }, [selectCartId]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    const discountData = localStorage.getItem("Discount code");
    const discountCode = JSON.parse(discountData as string);
    if (discountCode) {
      setPromoCode(discountCode?.name);
    }
  }, [cartId]);

  useEffect(() => {
    const id = localStorage.getItem("cartId");
    if (id && promoCode) {
      cartUpdate();
    }
  }, [promoCode]);

  const [cartUpdate] = useMutation(UPDATE_CART, {
    variables: {
      cartId: cartId,
      discountCodes: promoCode,
    },
    onCompleted: (data) => {
      setCartData({
        ...cartData,
        data,
      });
      localStorage.setItem(
        "webUrl",
        data?.cartDiscountCodesUpdate?.cart?.checkoutUrl
      );
    },
    onError: (error) => {
      console.log("Something went wrong", error);
    },
  });

  const { data } = useQuery(GET_USER, {
    variables: {
      customerAccessToken: token,
    },
    onCompleted: ({ customer }) => {
      setCustomer(customer);
    },
  });

  const [cartCreate] = useMutation(CREATE_CART, {
    variables: {
      cartInput: {
        lines: {
          quantity: quantity,
          sellingPlanId: planId,
          merchandiseId: variant?.id.split("_").pop(),
        },
        discountCodes: promoCode,
      },
    },
    onCompleted: ({ cartCreate }) => {
      localStorage.setItem("cartId", cartCreate?.cart?.id);
      dispatch({
        type: cartConstants.ADD_TO_CART_SUCCESS,
        payload: cartCreate.cart.lines.edges,
      });
      localStorage.setItem("webUrl", cartCreate?.cart?.checkoutUrl);
      setCartId(cartCreate?.cart?.id);
      setCartData(cartCreate?.cart);
      oneTime || monthly
        ? null
        : toast("Your item is now in cart", { type: "success" });
      setQuantity && setQuantity(1);
    },
  });

  const addItemsToCart = (id: string | null) => {
    cartAddItems({
      variables: {
        lines: [
          {
            merchandiseId: variant?.id.split("_").pop(),
            quantity: quantity,
            sellingPlanId: planId,
          },
        ],
        cartId: id,
      },
    }).then((data) => {
      ANALYTICS.addToCart(data, quantity, variant);
    });
  };

  const [cartAddItems] = useMutation(ADD_TO_CART, {
    onCompleted: ({ cartLinesAdd, ...rest }) => {
      setCartData({
        ...cartData,
        ...cartLinesAdd?.cart,
      });
      oneTime || monthly
        ? null
        : toast("Your item is now in cart", {
            type: "success",
            autoClose: 1000,
            hideProgressBar: true,
          });
      setQuantity && setQuantity(1);
    },
  });

  useEffect(() => {
    if (cartData.id) {
      dispatch({
        type: cartConstants.ADD_TO_CART_SUCCESS,
        payload: cartData?.lines,
      });
      if (token) {
        const id = localStorage.getItem("cartId");
        associateCustomerToCart(id);
      }
    }
  }, [cartData]);

  const [cartAssociate] = useMutation(CART_BUYER_IDENTITY);

  const associateCustomerToCart = (id: string | null) => {
    cartAssociate({
      variables: {
        cartId: id,
        buyerIdentityInput: {
          customerAccessToken: token,
          email: customer?.email,
          phone: customer?.phone,
        },
      },
    });
  };

  const handleClick = async () => {
    if (!quantity) {
      toast("Quantity must be greater than 0.", { type: "error" });
      return;
    }
    if (availableForSale) {
      const id = localStorage.getItem("cartId");
      if (!cartId && !id && id === null) {
        cartCreate();
      } else {
        addItemsToCart(id);
      }
    }
  };

  return (
    <div className={cn(s.container, { [s.fixedContainer]: isFixed === true })}>
      {!isBuyNow ? (
        <>
          <button
            className={cn(
              s.button,
              { [s.buttonAdd]: addButton === true },
              { [s.disableButton]: availableForSale === false },
              { [s.oneTime]: oneTime === true },
              { [s.monthly]: monthly === true }
            )}
            onClick={() => handleClick()}
            style={{
              ...(isFixed && {
                margin: 0,
                borderRadius: "0",
                height: "100%",
                fontSize: "16px",
              }),
            }}
          >
            {isFixed
              ? buttonText +
                " - " +
                (variant !== undefined && variant.priceV2
                  ? CurrencyList.get(variant.priceV2.currencyCode).symbol
                  : "") +
                checkedPrice
              : buttonText}
          </button>
        </>
      ) : (
        <button
          className={cn(s.button, {
            [s.disableButton]: availableForSale === false,
          })}
          onClick={() => handleClick()}
        >
          Buy Now
        </button>
      )}
    </div>
  );
};

export { AddToCartButton };
