import classNames from "classnames";
import React from "react";
import * as s from "./Quantity.module.scss";

const cn = classNames.bind(s);

interface IQuantity {
  newQuantity: number;
  setNewQuantity: any;
  disabled?: boolean;
  floor?: number;
}

const Quantity = ({
  newQuantity,
  setNewQuantity,
  disabled,
  floor,
}: IQuantity) => {
  const decreaseValue = () => {
    if (newQuantity > 1) {
      setNewQuantity(newQuantity - 1);
    }
  };
  const increaseValue = () => {
    setNewQuantity(newQuantity + 1);
  };
  const handleChange = (e: any) => {
    const numericalValue = Number(e.target.value);
    if (numericalValue && numericalValue !== 0) setNewQuantity(numericalValue);
    else if (e.target.value === "") setNewQuantity(0);
  };

  return (
    <div className={s.mainWrapper}>
      <button
        disabled={disabled}
        onClick={decreaseValue}
        className={cn(s.button, s.leftButton)}
      >
        -
      </button>
      <input
        disabled={disabled}
        value={newQuantity ? newQuantity : ""}
        onChange={(e) => handleChange(e)}
        className={s.inputField}
      ></input>
      <button
        disabled={disabled}
        onClick={increaseValue}
        className={cn(s.button, s.rightButton)}
      >
        +
      </button>
    </div>
  );
};

export default Quantity;
