/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userConstants } from "../../../modules/auth/constants";
import logo1 from "../../../images/logo1.svg";
import * as s from "./WebHeader.module.scss";
import { Link, navigate } from "gatsby";
import { AllCartItems } from "../../organisms/AllCartItems/AllCartItems";
import drop from "../../../images/dropdown.svg";
import classNames from "classnames";
import { selectLoggedIn } from "../../../modules/selectors";
import { cartConstants } from "../../../modules/cart/constants";

const cn = classNames.bind(s);

const WebHeader = () => {
  const [token, setToken] = useState<string | null>();
  const [checkoutId, setCheckoutId] = useState<string | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [cartItemsNumber, setCartItemsNumber] = useState(0);

  const loggedIn = useSelector(selectLoggedIn);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setCheckoutId(localStorage.getItem("checkoutId"));
  }, []);

  const handleCart = () => {
    setVisible(true);
  };

  const handleCartVisibility = (visible: boolean) => {
    setVisible(visible);
  };

  const dispatch = useDispatch();
  return (
    <div className={s.mainWrapper}>
      <div className={s.container}>
        <div className={s.firstGroup}>
          <Link to="/" className={s.link}>
            Home
          </Link>
          <Link to="/products" className={s.link}>
            Products
          </Link>
          <Link to="/meet-your-mushrooms" className={s.link}>
            Meet Your Mushrooms
          </Link>
          <Link to="/about-us" className={s.link}>
            About Us
          </Link>
        </div>

        <div className={s.logoDiv} onClick={() => navigate("/")}>
          <img src={logo1} alt="" className={s.logo}></img>
        </div>

        <div className={s.secondGroup}>
          <Link to="/blog" className={s.link}>
            {" "}
            Blog
          </Link>
          <Link to="/contact" className={s.link}>
            Contact
          </Link>

          {loggedIn || !!token ? (
            <ul className={s.mainDropdown}>
              <li className={s.liWrapper}>
                <p className={s.link}>Profile</p>
                <img src={drop} alt="dropdown" className={s.profileImage}></img>
              </li>

              <ul className={s.dropdown}>
                {/* Kad se user logina ybog ovog izbacuje u dropdownu opet profile
                  <li className={s.liWrapper}>
                  <p className={s.link}>Profile</p>
                  <img src={drop} alt="dropdown"></img>
                </li> */}
                <li>
                  <Link to="/profile" className={cn(s.link, s.logText)}>
                    Account
                  </Link>
                </li>
                <li>
                  <>
                    <Link
                      to="/login"
                      className={cn(s.link, s.logText)}
                      onClick={() => {
                        dispatch({ type: userConstants.LOGOUT });
                        localStorage.removeItem("token");
                        setToken(null);

                        dispatch({ type: cartConstants.EMPTY_CART });
                        localStorage.removeItem("checkoutId");
                        setCheckoutId(null);
                      }}
                    >
                      Log Out
                    </Link>
                  </>
                </li>
              </ul>
            </ul>
          ) : (
            <Link to="/login" className={s.link}>
              Log In
            </Link>
          )}

          <p className={s.link} onClick={() => handleCart()}>
            Cart <sup className={s.sup}>({cartItemsNumber})</sup>
          </p>
        </div>
      </div>

      <AllCartItems
        cart={true}
        checkoutId={checkoutId}
        visibility={visible}
        handleCart={handleCartVisibility}
        setNumber={setCartItemsNumber}
      />
    </div>
  );
};

export default WebHeader;
