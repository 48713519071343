/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import * as s from "./Footer.module.scss";
import logo1 from "../../../images/logo1.svg";
import ig from "../../../images/Social Icons.svg";
import twitter from "../../../images/twitter.svg";
import arrow from "../../../images/Vector.svg";
import fb from "../../../images/fb.svg";
import tik from "../../../images/tiktok.svg";
import { Link, navigate } from "gatsby";
import { PrivacyPolicy } from "../../../constants/privacyPolicy";
import { TermsOfService } from "../../../constants/termsOfService";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CUSTOMER } from "../../../g/mutations/customer";
import { GET_USER } from "../../../g/queries/get-user-info";
import { toast } from "react-toastify";
import PopUp from "../../molecules/PopUp/PopUp";

interface IFooter {
  token?: string | null;
}
const Footer = ({ token }: IFooter) => {
  const [popUp, showPopUp] = useState(false);
  const [popUpInfo, setPopUpInfo] = useState<IPopUpContent>();
  const [user, setUser] = useState<TroopState.User>();
  const [newsletterMail, setNewsletterMail] = useState<string>();

  useQuery(GET_USER, {
    variables: {
      customerAccessToken: token,
    },
    onCompleted: (data) => {
      setUser(data ? data.customer : null);
    },
  });

  const handlePopUp = (visible: boolean) => {
    showPopUp(visible);
  };
  const handleClick = (name: IPopUpContent) => {
    showPopUp(true);
    setPopUpInfo(name);
  };

  const [news] = useMutation(UPDATE_CUSTOMER);

  const handleGuestSubscription = () => {
    toast("Now we’re official 😍. Welcome to the troop.", { type: "success" });
    // navigate("/login", { state: { email: newsletterMail } });
  };

  return (
    <div className={s.mainWrapper}>
      <script
        async
        src="https://cdn.sweettooth.io/assets/storefront.js"
        charSet="utf-8"
      ></script>
      <div className={s.container}>
        <div className={s.companyInfo}>
          <img src={logo1} className={s.logo} alt=""></img>
          <p className={s.copyright}>
            Copyright © {new Date().getFullYear()} Troop. <br />
            <br />
            All rights reserved
          </p>

          <div className={s.icondiv}>
            <img
              src={ig}
              className={s.icon}
              alt=""
              onClick={() =>
                window.open("https://www.instagram.com/trytroop/?hl=en")
              }
            ></img>
            <img
              src={twitter}
              className={s.icon}
              alt=""
              onClick={() => window.open("https://twitter.com/TryTroop")}
            ></img>
            <img
              src={fb}
              className={s.icon}
              alt=""
              onClick={() => window.open("https://www.facebook.com/trytroop/")}
            ></img>
            <img
              src={tik}
              className={s.icon}
              alt=""
              onClick={() => window.open("https://www.tiktok.com/@trytroop?")}
            ></img>
          </div>
        </div>

        {/* <div className={s.wraper}> */}
        <div className={s.firstGroup}>
          <p className={s.paragraph}>Troop</p>
          <Link to="/" className={s.links}>
            Home
          </Link>
          <Link to="/products" className={s.links}>
            Products
          </Link>
          <Link to="/about-us" className={s.links}>
            About Us
          </Link>
          <Link to="/meet-your-mushrooms" className={s.links}>
            Meet Your Mushrooms
          </Link>
          <Link to="/catalog/#reviews1" className={s.links}>
            Testimonials
          </Link>
          <Link to="/blog" className={s.links}>
            Blog
          </Link>
        </div>

        <div className={s.secondGroup}>
          <p className={s.paragraph}>Support</p>
          <Link to="/contact" className={s.links}>
            Contact
          </Link>
          <Link to="/profile" className={s.links}>
            Profile
          </Link>
          <button
            className={s.links}
            onClick={() => handleClick(TermsOfService)}
          >
            TOS and Legal
          </button>
          <button
            className={s.links}
            onClick={() => handleClick(PrivacyPolicy)}
          >
            Privacy Policy
          </button>
          <p
            onClick={() => {
              navigate("/blog/#research");
              window.scrollTo(0, 0);
            }}
            className={s.links}
          >
            Research and Studies
          </p>
          <p
            onClick={() => {
              navigate("/blog/#faq");
              window.scrollTo(0, 0);
            }}
            className={s.links}
          >
            FAQ
          </p>
        </div>

        <div className={s.thirdGroup}>
          <p className={s.paragraph}>Stay in touch</p>
          {user ? (
            <div className={s.subscribe}>
              <button
                className={s.button}
                onClick={async () => {
                  const { data } = await news({
                    variables: {
                      input: {
                        acceptsMarketing: !user?.acceptsMarketing,
                      },
                      token: token,
                    },
                  });
                  if (data?.customerUpdate?.customer)
                    setUser(data?.customerUpdate?.customer);
                  if (data?.customerUpdate?.customer.acceptsMarketing)
                    toast("You're now subscribed to our newsletter.", {
                      type: "success",
                    });
                  else
                    toast("You will no longer receive email updates from us.", {
                      type: "success",
                    });
                }}
              >
                {user?.acceptsMarketing ? "Unsubscribe" : "Subscribe"}
              </button>
            </div>
          ) : (
            <div className={s.inputDiv}>
              <input
                type="text"
                placeholder="Your email address"
                className={s.input}
                onChange={(e) => setNewsletterMail(e.target.value)}
              ></input>
              <img
                onClick={handleGuestSubscription}
                src={arrow}
                className={s.arrowImg}
                alt=""
              ></img>
            </div>
          )}
          <p className={s.para}>
            Be the first to get updates on Troop products, offers and more.
          </p>
        </div>
        {/* </div> */}
        <PopUp info={popUpInfo} visible={popUp} handlePopUp={handlePopUp} />
      </div>
    </div>
  );
};

export default Footer;
