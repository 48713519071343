import { all, call } from "redux-saga/effects";
import appSaga from "./app/saga";

const sagas = [...appSaga];

function* root(): Generator {
  yield all(sagas.map((saga) => call(saga)));
}

export default root;
