import { takeLatest } from "redux-saga/effects";
import yotpoHelper from "../../helpers/yotpoHelper";
import { APP } from "./constants";

function* onTakeLatest() {
  yotpoHelper.fetchAuthToken();
  yield console.log("===> APP INIT <===");
}

function* watchAppInit(): Generator {
  yield takeLatest(APP.START, onTakeLatest);
}

export default [watchAppInit];
