/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import classNames from "classnames";
import * as s from "./RemoveFromCartButton.module.scss";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { cartConstants } from "../../../modules/cart/constants";
import bin from "../../../images/bin.svg";
import del from "../../../images/delete.svg";
import { REMOVE_ITEMS_FROM_CART } from "../../../g/mutations/createCart";

const cn = classNames.bind(s);
interface IRemoveFromCartButton {
  lineItemId: string;
  cartId?: string | null;
  removeBin?: boolean;
  setSubtotal?: (param: string) => void;
}

const RemoveFromCartButton = ({
  lineItemId,
  removeBin,
  setSubtotal,
  cartId,
}: IRemoveFromCartButton) => {
  const dispatch = useDispatch();

  const cart_id = localStorage.getItem("cartId");

  const [removeFromCart] = useMutation(REMOVE_ITEMS_FROM_CART, {
    variables: {
      id: cart_id,
      lines: [lineItemId],
    },
    onCompleted: ({ cartLinesRemove }) => {
      setSubtotal &&
        setSubtotal(
          cartLinesRemove?.cart?.estimatedCost?.subtotalAmount?.currencyCode +
            " " +
            Number(cartLinesRemove?.cart?.estimatedCost?.subtotalAmount?.amount)
              .toFixed(2)
              .toString()
        );
      cartLinesRemove?.cart &&
        dispatch({
          type: cartConstants.ADD_TO_CART_SUCCESS,
          payload: cartLinesRemove?.cart?.lines,
        });
    },
  });

  const handleClick = () => {
    removeFromCart();
  };

  return (
    <div className={cn(s.container)}>
      {removeBin ? (
        <img
          src={bin}
          onClick={() => handleClick()}
          alt="bin"
          className={s.bin}
        ></img>
      ) : !removeBin ? (
        <img src={del} onClick={() => handleClick()} alt="bin"></img>
      ) : null}
    </div>
  );
};

export { RemoveFromCartButton };
