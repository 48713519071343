import fetch from "node-fetch";

class YotpoHelper {
  public token: string | null = null;
  // constructor() {}

  async fetchAuthToken() {
    const jsonResresponse = await fetch("https://api.yotpo.com/oauth/token", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        client_id: process.env.GATSBY_YOTPO_APP_KEY,
        client_secret: process.env.GATSBY_YOTPO_SECREY_KEY,
        grant_type: "client_credentials",
      }),
    });

    const jsonRes = await jsonResresponse.json();

    window.localStorage.setItem("yotpo-token", jsonRes.access_token);
  }

  getAuthToken() {
    return window.localStorage.getItem("yotpo-token");
  }
}

const yotpoHelper = new YotpoHelper();

export default yotpoHelper;
