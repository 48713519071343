import "firebase/auth";
import wrapWithProvider from "./src/modules/wrap-with-provider";
import "./src/global-styles/globals.scss";
import "@fontsource/plus-jakarta-sans";
import { globalHistory } from "@reach/router/lib/history";

export const shouldUpdateScroll = () => true;

globalHistory.listen((data) => {
  if (data.action === "PUSH") {
    if (window.scrollY) {
      window.scroll(0, 0);
    }
  }
});

export const wrapRootElement = wrapWithProvider;
