import { userConstants } from "./constants";

const INITIAL_STATE = {
  loggedIn: false,
  user: {},
};

const authReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.REGISTER_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default authReducer;
