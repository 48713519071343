/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
class AnalyticsFactory {
  addToCart = (data: unknown, qty: any, variant: any) => {
    if (typeof window === "undefined") return;
    // @ts-ignore
    window.gtag &&
      // @ts-ignore
      window.gtag("event", "add_to_cart", {
        items: [
          {
            id: variant.shopifyId,
            name: variant.title,
            category: "Troop",
            brand: "Troop",
            variant: variant.selectedOptions[0].value,
            price: Number(variant.priceV2.amount),
            quantity: qty || 1,
          },
        ],
      });

    // @ts-ignore
    window.fbq &&
      // @ts-ignore
      window.fbq("track", "AddToCart", {
        content_name: variant.title,
        content_category: "Troop",
        content_ids: [variant.shopifyId],
        content_type: "product",
        value: Number(variant.priceV2.amount),
        currency: variant.priceV2.currencyCode,
      });
  };
}

export const ANALYTICS = new AnalyticsFactory();
