import { cartConstants } from "./constants";

const INITIAL_STATE = {
  id: "",
  lineItems: [],
};

const cartReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case cartConstants.ADD_TO_CART_SUCCESS:
      return {
        id: action.payload.id,
        lineItems: action.payload?.edges,
      };
    case cartConstants.ADD_TO_CART_FAILURE:
      return {
        id: null,
      };

    case cartConstants.EMPTY_CART:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default cartReducer;
