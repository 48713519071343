import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getCustomerFromToken($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      firstName
      lastName
      email
      id
      acceptsMarketing
      lastIncompleteCheckout {
        id
        lineItemsSubtotalPrice {
          amount
          currencyCode
        }
        lineItems(first: 4) {
          edges {
            node {
              title
              quantity

              id
              variant {
                id

                sellingPlanAllocations(first: 2) {
                  edges {
                    node {
                      sellingPlan {
                        name
                        options {
                          name
                          value
                        }
                      }
                    }
                  }
                }

                image {
                  originalSrc
                }

                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
      defaultAddress {
        address1
        address2
        city
        country
        province
        phone
        zip
        id
      }

      orders(first: 5) {
        edges {
          node {
            id
            name
            processedAt
            financialStatus
            fulfillmentStatus
            totalPriceV2 {
              amount
              currencyCode
            }
            shippingAddress {
              address1
              city
              zip
            }
            lineItems(first: 5) {
              edges {
                node {
                  title
                  quantity
                  variant {
                    id

                    product {
                      handle
                    }
                    image {
                      originalSrc
                    }

                    priceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
