import React from "react";
import MobileHeader from "../../molecules/MobileHeder/MobileHeader";
import WebHeader from "../../molecules/WebHeader/WebHeader";
import * as s from "./Header.module.scss";

interface IHeader {
  visibility: boolean;
  setVisibility: any;
}

const Header = ({ visibility, setVisibility }: IHeader) => {
  return (
    <>
      <WebHeader />
      <div className={s.mobile}>
        <MobileHeader visible={visibility} setVisible={setVisibility} />
      </div>
    </>
  );
};

export default Header;
