// export { default as appReducer } from "./app/reducer";

import { combineReducers } from "redux";
import appReducer from "./app/reducer";
import authReducer from "./auth/reducer";
import cartReducer from "./cart/reducer";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  app: appReducer,
  auth: authReducer,
  cart: cartReducer,
});

export default rootReducer;
