import React, { useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux";
import MainLayout from "../components/layouts/MainLayout";
import { useAppDispatch } from "../hooks";
import { appActions } from "./app/actions";
import { store } from "./configure-store";
import CreateGqlProvider from "./create-gql-provider";

const Component: React.FunctionComponent = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(appActions.start());
  }, []);
  return <>{children}</>;
};

const WrapWithProvider = ({
  element,
}: {
  element: JSX.Element;
}): JSX.Element => {
  return (
    <ReduxProvider store={store}>
      <CreateGqlProvider>
        <MainLayout>
          <Component>{element}</Component>
        </MainLayout>
      </CreateGqlProvider>
    </ReduxProvider>
  );
};

export default WrapWithProvider;
