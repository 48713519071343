/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import * as s from "./PopUp.module.scss";
import classNames from "classnames";
import closeModal from "../../../images/closeModal.png";

interface PopUpProps {
  info?: IPopUpContent;
  visible: boolean;
  handlePopUp: (a: boolean) => void;
}

const PopUp = ({ info, visible, handlePopUp }: PopUpProps) => {
  const cn = classNames.bind(s);

  const handle = () => {
    handlePopUp(false);
  };

  return (
    <div
      id="#popup1"
      className={cn(s.popupOverlay, { [s.popupOverlayShow]: visible })}
    >
      <div className={s.popup}>
        <div className={s.popupTitle}>
          <h2 className={s.title}>{info?.title}</h2>
          <button className={s.closeWrapper} onClick={handle}>
            <img className={s.close} src={closeModal} alt="close" />
          </button>
        </div>
        <div
          className={s.content}
          dangerouslySetInnerHTML={{ __html: info?.content as string }}
        />
      </div>
      {/* <button className={s.closeWrapper} onClick={handle}>
        <img src={closeModal} alt="close" />
      </button> */}
    </div>
  );
};

export default PopUp;
