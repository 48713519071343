// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-single-tsx": () => import("./../../../src/pages/blog-single.tsx" /* webpackChunkName: "component---src-pages-blog-single-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-discounts-tsx": () => import("./../../../src/pages/discounts.tsx" /* webpackChunkName: "component---src-pages-discounts-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-history-page-tsx": () => import("./../../../src/pages/history-page.tsx" /* webpackChunkName: "component---src-pages-history-page-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-meet-your-mushrooms-tsx": () => import("./../../../src/pages/meet-your-mushrooms.tsx" /* webpackChunkName: "component---src-pages-meet-your-mushrooms-tsx" */),
  "component---src-pages-meet-your-shroom-single-tsx": () => import("./../../../src/pages/meet-your-shroom-single.tsx" /* webpackChunkName: "component---src-pages-meet-your-shroom-single-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */),
  "component---src-templates-discount-tsx": () => import("./../../../src/templates/discount.tsx" /* webpackChunkName: "component---src-templates-discount-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-qr-tsx": () => import("./../../../src/templates/qr.tsx" /* webpackChunkName: "component---src-templates-qr-tsx" */),
  "component---src-templates-reset-password-tsx": () => import("./../../../src/templates/reset-password.tsx" /* webpackChunkName: "component---src-templates-reset-password-tsx" */)
}

