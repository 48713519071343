// extracted by mini-css-extract-plugin
export var container = "WebHeader-module--container--zDJh3";
export var dropdown = "WebHeader-module--dropdown--Nyz8j";
export var firstGroup = "WebHeader-module--first-group--EMgSe";
export var liWrapper = "WebHeader-module--li-wrapper--QVVYO";
export var link = "WebHeader-module--link--4GsNI";
export var logText = "WebHeader-module--log-text--tgkXZ";
export var logo = "WebHeader-module--logo--iwbPk";
export var logoDiv = "WebHeader-module--logo-div--YZeob";
export var mainDropdown = "WebHeader-module--main-dropdown--ZId6z";
export var mainWrapper = "WebHeader-module--mainWrapper--GTgv7";
export var profileImage = "WebHeader-module--profile-image--RtpC+";
export var secondGroup = "WebHeader-module--second-group--iO3B6";
export var sup = "WebHeader-module--sup--nSzlR";