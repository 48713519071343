import { Buffer } from "buffer";

export const decodeBase64 = (obj: string) => {
  // base64 to string
  const base64ToString = Buffer.from(obj, "base64");
  return base64ToString.toString().split("/").pop();
};

export const getProductReviews = async (
  yotpoAppKey: string,
  productId: string
) => {
  const pr = decodeBase64(productId);

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `https://api.yotpo.com/v1/widget/${yotpoAppKey}/products/${pr}/reviews.json?per_page=150`,
    options
  );

  if (!response) throw new Error("Something went wrong!");
  const responseBody = await response.json();
  return responseBody;
  // const reviews = responseBody.response.reviews || [];
  // return await reviews;
};

export const getStarsDist = async (yotpoAppKey: string, productId: string) => {
  const pr = decodeBase64(productId);

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `https://api.yotpo.com/v1/widget/${yotpoAppKey}/products/${pr}/reviews.json`,
    options
  );

  if (!response) throw new Error("Something went wrong!");
  const responseBody = await response.json();
  const reviews = responseBody.response.bottomline || [];
  return await reviews;
};

export const getAllReviewsForTroop = async (
  yotpoAppKey: string,
  yotpoToken: string
) => {
  //const user = decodeBase64(userId);

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `https://api.yotpo.com/v1/apps/${yotpoAppKey}/reviews?utoken=${yotpoToken}`,
    options
  );

  if (!response) throw new Error("Something went wrong!");
  const responseBody = await response.json();
  return await responseBody;
};

export const getProductReviewsPerPage = async (
  yotpoAppKey: string,
  productId: string,
  page: string,
  perPage?: string
) => {
  const pr = decodeBase64(productId);

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `https://api.yotpo.com/v1/widget/${yotpoAppKey}/products/${pr}/reviews.json?per_page=${
      perPage || 6
    }&&page=${page}`,
    options
  );

  if (!response) throw new Error("Something went wrong!");
  const responseBody = await response.json();
  const reviews = responseBody.response || {};
  return await reviews;
};

export const voteOnReview = async (
  reviewId: string,
  voteType: string,
  cancleVote: boolean
) => {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (cancleVote === false) {
    const response = await fetch(
      `https://api.yotpo.com/reviews/${reviewId}/vote/${voteType}`,
      options
    );
    if (!response) throw new Error("Something went wrong!");
    const responseBody = await response.json();
    return await responseBody;
  } else {
    const response = await fetch(
      `https://api.yotpo.com/reviews/${reviewId}/vote/${voteType}/${cancleVote}`,
      options
    );
    if (!response) throw new Error("Something went wrong!");
    const responseBody = await response.json();
    return await responseBody;
  }
};
export const getVotes = async (yotpoAppKey: string, reviewId: string) => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `https://api.yotpo.com/reviews/${reviewId}?utoken=${yotpoAppKey}`,
    options
  );
  if (!response) throw new Error("Something went wrong!");
  const data = await response.json();
  const responseData = {
    likes: data?.response?.review?.votes_up,
    dislikes: data?.response?.review?.votes_down,
  };
  return responseData;
};
