import { gql } from "@apollo/client";

export const CREATE_CART = gql`
  mutation cartCreateMutation($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      cart {
        id
        estimatedCost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        discountCodes {
          code
        }
        checkoutUrl
        lines(first: 20) {
          edges {
            node {
              quantity
              id
              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
                subtotalAmount {
                  amount
                  currencyCode
                }
              }

              merchandise {
                ... on ProductVariant {
                  id
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                  product {
                    id
                    handle
                    images(first: 2) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(lines: $lines, cartId: $cartId) {
      cart {
        id
        estimatedCost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        checkoutUrl
        lines(first: 20) {
          edges {
            node {
              quantity
              id
              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
                subtotalAmount {
                  amount
                  currencyCode
                }
              }

              merchandise {
                ... on ProductVariant {
                  id
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                  product {
                    id
                    handle
                    images(first: 2) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

export const CART_BUYER_IDENTITY = gql`
  mutation cartBuyerIdentityUpdate(
    $cartId: ID!
    $buyerIdentityInput: CartBuyerIdentityInput!
  ) {
    cartBuyerIdentityUpdate(
      cartId: $cartId
      buyerIdentity: $buyerIdentityInput
    ) {
      cart {
        id
        buyerIdentity {
          email
          phone
          countryCode
        }
      }
    }
  }
`;

export const REMOVE_ITEMS_FROM_CART = gql`
  mutation cartLinesRemoveMutation($id: ID!, $lines: [ID!]!) {
    cartLinesRemove(cartId: $id, lineIds: $lines) {
      cart {
        id
        estimatedCost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        lines(first: 20) {
          edges {
            node {
              id
              quantity

              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
                subtotalAmount {
                  amount
                  currencyCode
                }
              }

              merchandise {
                ... on ProductVariant {
                  title
                  image {
                    originalSrc
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }

                  product {
                    id
                    title
                    images(first: 2) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ITEMS_IN_CART = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        estimatedCost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
        lines(first: 20) {
          edges {
            node {
              id
              quantity

              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
                subtotalAmount {
                  amount
                  currencyCode
                }
              }

              merchandise {
                ... on ProductVariant {
                  product {
                    id
                    title
                    images(first: 2) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                  }

                  image {
                    originalSrc
                  }
                  id
                  title
                }
              }
            }
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;
