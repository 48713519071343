import React, { useEffect, useState } from "react";
import Footer from "../../organisms/Footer/Footer";
import Header from "../../organisms/Header/Header";
import * as s from "./index.module.scss";
import { ToastContainer } from "react-toastify";

const MainLayout = ({ children }: { children: JSX.Element }) => {
  const [token, setToken] = useState<string | null>();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    }
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  }, []);

  return (
    <>
      <div
        style={{
          ...(isCartOpen && { height: "100vh", overflow: "hidden" }),
        }}
      >
        <Header visibility={isCartOpen} setVisibility={setIsCartOpen} />
        <div className={s.container}>
          <div className={s.headerPart}></div>
          {children}
        </div>
        <ToastContainer />
        {loaded ? <Footer token={token} /> : <></>}
      </div>
    </>
  );
};

export default MainLayout;
