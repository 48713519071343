// extracted by mini-css-extract-plugin
export var arrowImg = "Footer-module--arrow-img--bMU8C";
export var button = "Footer-module--button--i2aSp";
export var companyInfo = "Footer-module--company-info--8VIPG";
export var container = "Footer-module--container--S7i5t";
export var copyright = "Footer-module--copyright--ieSAp";
export var firstGroup = "Footer-module--first-group--kIoTW";
export var icon = "Footer-module--icon--iHdFO";
export var icondiv = "Footer-module--icondiv--KoyGK";
export var input = "Footer-module--input--Y9l46";
export var inputDiv = "Footer-module--input-div--CB7oq";
export var links = "Footer-module--links--iY8ZW";
export var logo = "Footer-module--logo--7vk7d";
export var mainWrapper = "Footer-module--main-wrapper--6WCdR";
export var para = "Footer-module--para--jwILV";
export var paragraph = "Footer-module--paragraph--h41C0";
export var secondGroup = "Footer-module--second-group--uY341";
export var subscribe = "Footer-module--subscribe--8CaGv";
export var thirdGroup = "Footer-module--third-group--WWKMh";
export var wraper = "Footer-module--wraper--V4syh";