/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import * as s from "./CartItem.module.scss";
import update from "../../../images/update.svg";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { RemoveFromCartButton } from "../RemoveFromCartButton/RemoveFromCartButton";
import { cartConstants } from "../../../modules/cart/constants";
import { useDispatch } from "react-redux";
import { ImImage } from "react-icons/im";
import { navigate } from "gatsby";
import Quantity from "../Quantity/Quantity";
import { decodeBase64 } from "../../../constants/yotpoReviews";
import { GET_PLAN_BY_PRODUCT_ID } from "../../../g/queries/get-plans";
import { get } from "lodash";
import { useGetAllShopifyProducts } from "../../../g/queries/get-all-shopify-products";
import { AddToCartButton } from "../AddToCartButton/AddToCartButton";
import { UPDATE_ITEMS_IN_CART } from "../../../g/mutations/createCart";

const cn = classNames.bind(s);

interface ICartItem {
  image?: string;
  image1?: string;
  text: string;
  amount?: number;
  price?: string;
  currency?: string;
  checkoutId?: string | null;
  cart?: boolean;
  product?: Product;
  completeSet?: boolean;
  setSubtotal?: (param: string) => void;
  handleCart?: (a: boolean) => void;
  cartId?: string | null;
  merchandiseId?: string;
  addButton?: boolean;
}

const CartItem = ({
  image,
  image1,
  text,
  amount,
  price,
  currency,
  cart,
  product,
  completeSet,

  setSubtotal,
  cartId,
  addButton,
}: ICartItem) => {
  const [quantity, setQuantity] = useState<number>(Number(amount));
  const dispatch = useDispatch();
  const [cartID, setCartID] = useState<string | null>("");
  const [subsText, setSubsText] = useState<string>();
  const [plans, setPlans] = useState<IPlans>({
    id: "",
    name: "",
    priceAdjustments: [],
  });
  const [discount, setDiscount] = useState(1);
  const [discountPrice, setDiscountPrice] = useState<number>(0);

  currency = currency?.replace("USD", "$");

  useEffect(() => {
    setCartID(localStorage.getItem("cartId"));
    // setQuantity(Number(amount));
    const priceInCart = Number(price);
    if (price && quantity && priceInCart / quantity === 36) {
      setSubsText("Monthly");
    } else if (price && quantity && priceInCart / quantity === 45) {
      setSubsText("One time");
    }
  }, [price]);

  // useEffect(() => {
  //   if (Number(amount) !== quantity) setQuantity(Number(amount));
  //   // setResetQuantity(true);

  //   // console.log(amount);
  //   // console.log(price);
  // }, [amount]);

  useEffect(() => {
    handleQuantity();
  }, [quantity]);

  const [updateCart] = useMutation(UPDATE_ITEMS_IN_CART, {
    onCompleted: (data) => {
      dispatch({
        type: cartConstants.ADD_TO_CART_SUCCESS,
        payload: data?.cartLinesUpdate?.cart?.lines,
      });
    },
  });

  const [sellingPlans] = useLazyQuery(GET_PLAN_BY_PRODUCT_ID, {
    fetchPolicy: "network-only",
    onCompleted: ({ product }) => {
      if (
        product?.sellingPlanGroups?.edges &&
        product?.sellingPlanGroups?.edges[0]?.node &&
        product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges &&
        product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges[0]?.node
      ) {
        const data =
          product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges[0]
            ?.node;
        const adminId = "gid://shopify/SellingPlan/" + decodeBase64(data?.id);
        setPlans({
          ...data,
          id: adminId,
        });
        const adjustment = get(
          data.priceAdjustments[0],
          "adjustmentValue.adjustmentPercentage"
        );

        setDiscount(adjustment);
      }
    },
  });

  useEffect(() => {
    const id =
      "gid://shopify/Product/" + decodeBase64(product?.shopifyId || "");
    sellingPlans({
      variables: {
        id,
      },
    });
  }, [product?.id]);

  useEffect(() => {
    if (discount !== 1) {
      const disc = Number(price) * (1 - discount / 100);
      setDiscountPrice(disc);
    }
  }, [discount]);

  const handleClick = () => {
    if (product && product.merchandise?.product.handle) {
      navigate("/products/" + product?.merchandise?.product?.handle);
    }
  };

  const handleQuantity = () => {
    if (quantity && cartID) {
      updateCart({
        variables: {
          cartId: cartID,
          lines: {
            quantity: quantity,
            id: product?.id,
            merchandiseId: product?.variant?.id,
          },
        },
      });
    }
  };

  return (
    <div
      className={
        cart
          ? cn(s.containerCart, { [s.containerCart1]: completeSet === true })
          : s.container
      }
    >
      <div className={s.imageWrapper}>
        {image === undefined ? (
          <ImImage style={{ width: "100%" }} />
        ) : (
          <img
            src={image1 || image}
            alt="mushroom"
            className={s.gummies}
            onClick={handleClick}
          ></img>
        )}
      </div>

      <div className={s.mainContentWrapper}>
        <div
          className={cn(
            s.textWrapper,
            { [s.textWrapperCart]: cart === true },
            { [s.textWrapperCompleteSet]: completeSet === true }
          )}
        >
          {!cart ? (
            <p className={cn(s.text)}>{text} daily mushroom gummy</p>
          ) : (
            <p className={cn(s.text, { [s.textCart]: cart === true })}>
              <span className={cn({ [s.spanText]: cart === true })}>
                {text}
              </span>{" "}
            </p>
          )}

          {cart && !completeSet && product && (
            <>
              {
                <RemoveFromCartButton
                  lineItemId={product.id}
                  cartId={cartId}
                  removeBin={true}
                  setSubtotal={setSubtotal}
                />
              }
            </>
          )}
        </div>

        <div
          className={cn(s.anotherWrapper, {
            [s.anotherWrapperCart]: cart === true,
            [s.anotherWrapperCompleteSet]: completeSet === true,
          })}
        >
          {!completeSet && (
            <Quantity
              newQuantity={Number(amount)}
              setNewQuantity={setQuantity}
              floor={1}
            />
          )}

          {cart && completeSet && (
            <div className={cn(s.amountWrapper, s.amountWrapper1)}>
              {plans.id && (
                <AddToCartButton
                  variant={product?.variants?.[0]}
                  availableForSale={product?.availableForSale}
                  monthly={true}
                  buttonText="Monthly"
                  quantity={1}
                  planId={plans.id}
                />
              )}
              <AddToCartButton
                variant={product?.variants?.[0]}
                availableForSale={product?.availableForSale}
                oneTime={true}
                quantity={1}
                buttonText="One Time"
              />
            </div>
          )}

          {completeSet ? (
            <div className={s.priceContainer}>
              {discountPrice && (
                <p className={s.priceDiscount}>
                  {currency}
                  {Number(discountPrice).toFixed(2).toString()}
                </p>
              )}
              <p className={s.priceComplete}>
                {currency}
                {Number(price).toFixed(2).toString()}
              </p>
            </div>
          ) : (
            quantity && (
              <p className={s.price}>
                <span className={s.subsText}>{subsText}</span>
                {currency}
                {Number(price).toFixed(2).toString()}
              </p>
            )
          )}
        </div>
      </div>

      {!cart && product && (
        <div className={s.updateWrapper}>
          <div className={s.deleteWrapper}>
            <img src={update} alt="update"></img>
            <span className={s.cancelhelptext}>Update order</span>
          </div>
          <div className={s.deleteWrapper}>
            <RemoveFromCartButton
              lineItemId={product.id}
              cartId={cartId}
              removeBin={false}
              setSubtotal={setSubtotal}
            />
            <span className={s.cancelhelptext}>Cancel anytime</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartItem;
