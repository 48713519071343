/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import * as s from "./AllCartItems.module.scss";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { selectCartItems } from "../../../modules/selectors";
import CartItem from "../../atoms/CartItem/CartItem";
import close from "../../../images/close.svg";
import { useGetAllShopifyProducts } from "../../../g/queries/get-all-shopify-products";
import { toast } from "react-toastify";
import { GET_CART_BY_ID } from "../../../g/queries/get-cart-info";
import { get } from "lodash";
import arrow from "../../../images/arrowUp.svg";
import { UPDATE_CART } from "../../../g/mutations/updateCart";

const cn = classNames.bind(s);

interface AllCartItemsProps {
  checkoutId?: string | null;
  cart?: boolean;
  visibility?: boolean;
  handleCart?: (a: boolean) => void;
  setNumber?: (a: number) => void;
}

const AllCartItems = ({
  cart,
  visibility,
  handleCart,
  setNumber,
}: AllCartItemsProps) => {
  const [cartItems, setCartItems] = useState<ProductNode[]>([]);
  const cartItemSelector = useSelector(selectCartItems);

  const { allShopifyProduct } = useGetAllShopifyProducts();
  const products = allShopifyProduct.edges.filter(
    (el: ProductNode) => el.node.productType === "Gummies"
  );
  const [checkout, setCheckout] = useState<string | null>("");
  const [cartId, setCartId] = useState<string | null>("");
  const [cartData, setCartData] = useState<TroopState.EndpointPayload>({});
  const [webUrl, setWebUrl] = useState<string | null>("");
  const [subtotal, setSubtotal] = useState<string>("0");
  const [promoCode, setPromoCode] = useState<string>("");
  const [fullPrice, setFullPrice] = useState<number>();
  const [promoCodePrice, setPromoCodePrice] = useState<number>();
  const [completeSetProducts, setCompleteSetProducts] = useState<ProductNode[]>(
    []
  );

  const [isCompleteSetOpen, setIsCompleteSetOpen] = useState(true);

  const getQuantity = () => {
    let quantity = 0;
    (cartItemSelector?.length > 0 ? cartItemSelector : cartItems).map(
      (el: ProductNode) => {
        el?.node?.quantity ? (quantity = quantity + el?.node?.quantity) : null;
      }
    );
    return quantity;
  };

  useEffect(() => {
    setCheckout(localStorage.getItem("checkoutId"));
    setCartId(localStorage.getItem("cartId"));
  }, []);

  useEffect(() => {
    const discountData = localStorage.getItem("Discount code");
    const discountCode = JSON.parse(discountData as string);
    if (discountCode && fullPrice) {
      const promoCodeName = discountCode?.name;
      setPromoCode(promoCodeName);
      if (discountCode.discountType === "DiscountAmount") {
        const amount = discountCode?.value;
        const promo = Number(fullPrice) - amount;
        setPromoCodePrice(promo);
      } else if (discountCode.discountType === "DiscountPercentage") {
        const percentage = discountCode?.value;
        const promo = Number(fullPrice) * (1 - percentage);
        setPromoCodePrice(promo);
      }
    }
  }, [subtotal]);

  useEffect(() => {
    const completeQuantity = getQuantity();
    if (
      (cartItemSelector?.length === undefined ||
        cartItemSelector?.length === 0) &&
      cartItems.length > 0
    ) {
      //setNumber && setNumber(cartItems.length);
      setNumber && setNumber(completeQuantity);
      completeSetFunction();
    } else if (
      cartItemSelector?.length === undefined &&
      cartItems.length === 0
    ) {
      setNumber && setNumber(0);
      completeSetFunction();
    } else {
      //setNumber && setNumber(cartItemSelector?.length);
      setNumber && setNumber(completeQuantity);
      completeSetFunction();
      if (cartItemSelector.length > 0) {
        const totalAmount = cartItemSelector.reduce(
          (prevValue: number, currentValue: any) => {
            return (
              Number(currentValue.node.estimatedCost.subtotalAmount.amount) +
              prevValue
            );
          },
          0
        );
        setFullPrice(totalAmount.toFixed(2));
        setSubtotal("$" + totalAmount.toFixed(2));
      }
    }
  }, [cartItemSelector, cartItems]);

  useEffect(() => {
    setWebUrl(localStorage.getItem("webUrl"));
    if (allItems === undefined) {
      const id = localStorage.getItem("cartId");
      getAllItems({
        variables: {
          id: id,
        },
      });
    }

    if (cartItems.length > 0) setCartId(localStorage.getItem("cartId"));
    if (localStorage.getItem("cartId") === null) {
      setCartItems([]);
      setNumber && setNumber(0);
    }
    if (allItems?.cart?.estimatedCost?.subtotalAmount?.amount !== undefined) {
      setSubtotal(
        allItems?.cart?.estimatedCost?.subtotalAmount?.currencyCode +
          Number(allItems?.cart?.estimatedCost?.subtotalAmount?.amount)
            .toFixed(2)
            .toString()
      );
      setFullPrice(
        Number(allItems?.cart?.estimatedCost?.subtotalAmount?.amount)
      );
    } else setSubtotal("");
  }, [visibility]);

  useEffect(() => {
    const discountData = localStorage.getItem("Discount code");
    const discountCode = JSON.parse(discountData as string);
    if (discountCode) {
      setPromoCode(discountCode?.name);
    }
  }, [cartId]);

  useEffect(() => {
    if (subtotal.includes("USD")) setSubtotal(subtotal?.replace("USD", "$"));
  }, [subtotal]);

  useEffect(() => {
    const id = localStorage.getItem("cartId");
    if (id && promoCode) {
      cartUpdate();
    }
  }, [promoCode]);

  const [getAllItems] = useLazyQuery(GET_CART_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setSubtotal(
        data?.cart?.estimatedCost?.subtotalAmount?.currencyCode +
          Number(data?.cart?.estimatedCost?.subtotalAmount?.amount)
            .toFixed(2)
            .toString()
      );
      setFullPrice(Number(data?.cart?.estimatedCost?.subtotalAmount?.amount));
    },
  });

  const { data: allItems } = useQuery(GET_CART_BY_ID, {
    variables: {
      id: cartId,
    },
    onCompleted: ({ cart }) => {
      setWebUrl(cart?.checkoutUrl);
      setSubtotal(
        cart?.estimatedCost?.subtotalAmount?.currencyCode +
          Number(cart?.estimatedCost?.subtotalAmount?.amount)
            .toFixed(2)
            .toString()
      );
      setFullPrice(Number(cart?.estimatedCost?.subtotalAmount?.amount));
      if (cart?.lines?.edges) {
        setCartItems(cart?.lines?.edges);
      }
    },
  });

  const completeSetFunction = () => {
    const cartItemsIds: string[] = [];

    (cartItemSelector?.length > 0 ? cartItemSelector : cartItems).map(
      (i: ProductNode) => {
        if (i?.node?.merchandise?.id)
          cartItemsIds.push(i?.node?.merchandise?.id);
      }
    );

    const missingProductsFromSet: ProductNode[] = [];

    products.filter((p: ProductNode) => {
      const id = p?.node?.variants[0]?.id.split("_").pop();
      if (id && !cartItemsIds.includes(id as string)) {
        missingProductsFromSet.push(p);
      }
    });

    setCompleteSetProducts(missingProductsFromSet);
  };

  const handleProceedToCheckout = () => {
    if (webUrl !== "") {
      const url = localStorage.getItem("webUrl");
      window.location.href = (webUrl as string) || (url as string);
      localStorage.removeItem("cartId");
      localStorage.removeItem("Discount code");
    } else if (webUrl === "")
      toast("Please log in to proceed!", { type: "info" });
  };

  const closeModal = () => {
    handleCart !== undefined ? handleCart(false) : null;
  };

  const [cartUpdate] = useMutation(UPDATE_CART, {
    variables: {
      cartId: cartId,
      discountCodes: promoCode,
    },
    onCompleted: (data) => {
      setCartData({
        ...cartData,
        data,
      });
      localStorage.setItem(
        "webUrl",
        data?.cartDiscountCodesUpdate?.cart?.checkoutUrl
      );
    },
    onError: (error) => {
      console.log("Something went wrong", error);
    },
  });

  // useEffect(() => {
  //   console.log(cartItemSelector);
  // }, [cartItemSelector]);

  return (
    <>
      <div
        onClick={closeModal}
        className={cn({ [s.emptyWrapper]: cart === true })}
        style={
          visibility && cart
            ? { visibility: "visible" }
            : !cart
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      ></div>
      <div
        className={cn(s.wrapper, {
          [s.wrapperCart]: cart === true,
        })}
        style={
          visibility && cart
            ? { visibility: "visible" }
            : !cart
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {cart && (
          <div className={s.cartWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className={s.cart}>
                Your <span>cart</span>
              </p>
              <div className={s.closeWrapper}>
                <img src={close} onClick={() => closeModal()} alt="close"></img>
              </div>
            </div>
          </div>
        )}

        <div
          className={cn(
            s.container,
            { [s.containerC]: cart === true }
            // { [s.containerCC]: completeSetProducts.length === 0 }
          )}
        >
          {(cartItemSelector?.length > 0 ? cartItemSelector : cartItems).map(
            (el: ProductNode, index: number) => {
              const merch = get(el, "node.merchandise");
              const q = get(el, "node.quantity");

              const price = get(el, "node.estimatedCost.subtotalAmount.amount");
              const currency = get(
                el,
                "node.estimatedCost.subtotalAmount.currencyCode"
              );
              return (
                <CartItem
                  text={merch?.product?.title}
                  image={
                    merch?.product?.title.includes("Prepaid") ||
                    merch?.product?.title.includes("Pack")
                      ? merch?.product?.images.edges[0]?.node?.originalSrc
                      : merch?.product?.images.edges[1]?.node?.originalSrc
                  }
                  amount={q}
                  price={price}
                  currency={currency}
                  // checkoutId={checkout}
                  cart={cart}
                  cartId={cartId}
                  key={index}
                  product={el.node}
                  setSubtotal={setSubtotal}
                  merchandiseId={merch?.id}
                />
              );
            }
          )}
        </div>

        <div
          className={s.completeSetWrapper}
          style={
            cartItemSelector?.edges?.length === 0 || cartItems?.length === 0
              ? { boxShadow: "none" }
              : {}
          }
        >
          {cart && (
            <div className={cn(s.completeSet)}>
              {completeSetProducts.length > 0 && (
                <div className={s.completeSetTitle}>
                  <p className={s.completeText}>Complete set</p>

                  <div
                    className={s.completeSetTitleWrapper}
                    onClick={() => setIsCompleteSetOpen(!isCompleteSetOpen)}
                  >
                    {isCompleteSetOpen ? (
                      <img src={close} alt="close"></img>
                    ) : (
                      <img src={arrow} alt="open" className={s.openBtn} />
                    )}
                  </div>
                </div>
              )}

              {isCompleteSetOpen ? (
                <div className={s.setWrapper}>
                  {completeSetProducts.map((el: ProductNode, index: number) => {
                    return (
                      <div className={s.itemWrapper} key={index}>
                        <CartItem
                          text={el.node.title}
                          image={el.node?.images[0]?.originalSrc}
                          image1={el.node?.images[1]?.originalSrc}
                          amount={1}
                          price={el?.node?.variants[0]?.priceV2?.amount}
                          currency={
                            el?.node?.variants[0]?.priceV2?.currencyCode
                          }
                          checkoutId={checkout}
                          cart={cart}
                          key={index}
                          product={el.node}
                          completeSet={true}
                          handleCart={handleCart}
                          cartId={cartId}
                          addButton={true}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}

          <div className={s.priceWrapper}>
            {cart ? (
              <>
                {promoCode && (
                  <p className={s.promoCodeText}>
                    Promo Code {promoCode} successfully applied!
                  </p>
                )}
                <div className={s.priceDetailsRow}>
                  <p className={cn(s.completeText)}>Subtotal </p>
                  <div
                    className={cn({
                      [s.promoContainerHidden]:
                        subtotal === "$0.00" || subtotal === "USD0.00",
                      [s.promoContainer]:
                        subtotal !== "$0.00" && subtotal !== "USD0.00",
                    })}
                  >
                    {subtotal && (
                      <p
                        className={cn(s.completeText, {
                          [s.priceStrikeThrough]: promoCode !== "",
                        })}
                      >
                        {subtotal}
                      </p>
                    )}

                    {promoCode && promoCodePrice && (
                      <p
                        className={cn(s.promoPrice, {
                          [s.promoHidden]: fullPrice === 0,
                        })}
                      >
                        ${promoCodePrice?.toFixed(2)}
                      </p>
                    )}
                  </div>
                </div>
                <p className={cn(s.completeText)}>Free shipping</p>
              </>
            ) : null}

            {cart && (
              <div className={s.btnContainer}>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className={s.buttonKeep}
                >
                  keep shopping
                </button>
                <button
                  type="button"
                  onClick={handleProceedToCheckout}
                  className={s.button}
                >
                  Checkout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { AllCartItems };
