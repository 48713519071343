import { AnyAction } from "redux";
import { APP, APP_INITIAL_STATE } from "./constants";

const appReducer = (
  state = APP_INITIAL_STATE,
  action: AnyAction
): TroopState.AppState => {
  switch (action.type) {
    case APP.START:
      return {
        ...state,
        live: true,
      };
    default:
      return state;
  }
};

export default appReducer;
